/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Type of the accident damage reason
 */
export enum AccidentDamageReasons {
  ANIMAL_COLLISION = "ANIMAL_COLLISION",
  DRIVING_OFF_THE_ROAD = "DRIVING_OFF_THE_ROAD",
  FIXED_OBJECT_COLLISION = "FIXED_OBJECT_COLLISION",
  OTHER = "OTHER",
  OVERTAKING_LANE_CHANGE = "OVERTAKING_LANE_CHANGE",
  PARKED_CAR_COLLISION = "PARKED_CAR_COLLISION",
  PERSON_COLLISION = "PERSON_COLLISION",
  RED_LIGHT_STOP_SIGN = "RED_LIGHT_STOP_SIGN",
  REVERSING_MANEUVERING = "REVERSING_MANEUVERING",
  RUNNING_OVER = "RUNNING_OVER",
  THEFT = "THEFT",
  TURNING = "TURNING",
  VANDALISM = "VANDALISM",
}

export enum AccidentQuestionsTripTypeEnum {
  BUSINESS = "BUSINESS",
  COMMUTE = "COMMUTE",
  PRIVATE = "PRIVATE",
}

/**
 * Type of the accident responsibility causer
 */
export enum AccidentResponsibilityCauser {
  BOTH_CAUSED_ACCIDENT = "BOTH_CAUSED_ACCIDENT",
  DRIVER_CAUSED_ACCIDENT = "DRIVER_CAUSED_ACCIDENT",
  OTHER_PARTY_CAUSED_ACCIDENT = "OTHER_PARTY_CAUSED_ACCIDENT",
  RESPONSIBILITY_IS_NOT_CLEAR = "RESPONSIBILITY_IS_NOT_CLEAR",
}

export enum AdditionalServiceType {
  CAR_REPLACEMENT = "CAR_REPLACEMENT",
  CLEANING = "CLEANING",
  PICK_UP = "PICK_UP",
}

/**
 * Type of the app feature
 */
export enum AppFeatureType {
  ACCIDENT_REPORT = "ACCIDENT_REPORT",
  ACCIDENT_WITHOUT_ANOTHER_CAR_REPORT = "ACCIDENT_WITHOUT_ANOTHER_CAR_REPORT",
  ADDITIONAL_SERVICES_DYNAMIC = "ADDITIONAL_SERVICES_DYNAMIC",
  ADDITIONAL_SERVICES_STATIC = "ADDITIONAL_SERVICES_STATIC",
  CAR_CHECK = "CAR_CHECK",
  CAR_CONDITION = "CAR_CONDITION",
  CAR_DOCUMENTS = "CAR_DOCUMENTS",
  CAR_IDENTIFICATION = "CAR_IDENTIFICATION",
  CAR_PARTS = "CAR_PARTS",
  CHECKLIST = "CHECKLIST",
  CONFIRMATION_PAGE = "CONFIRMATION_PAGE",
  DAMAGE_PHOTOS = "DAMAGE_PHOTOS",
  DAMAGE_REASONS = "DAMAGE_REASONS",
  DRIVER_INFORMATION = "DRIVER_INFORMATION",
  EXTERIOR_DAMAGE_REPORT = "EXTERIOR_DAMAGE_REPORT",
  HOTLINE_FLOW = "HOTLINE_FLOW",
  INTERIOR_DAMAGE_REPORT = "INTERIOR_DAMAGE_REPORT",
  MANAGER_LOGIN = "MANAGER_LOGIN",
  MECHANICAL_DAMAGE_REPORT = "MECHANICAL_DAMAGE_REPORT",
  MILEAGE = "MILEAGE",
  ONBOARDING = "ONBOARDING",
  SERVICES = "SERVICES",
  SERVICE_LIGHTS_REPORT = "SERVICE_LIGHTS_REPORT",
  SIMPLE_ACCIDENT_REPORT = "SIMPLE_ACCIDENT_REPORT",
  THIRD_PARTY_REPORT = "THIRD_PARTY_REPORT",
}

/**
 * App name
 */
export enum AppName {
  DRIVER_FACING_APP = "DRIVER_FACING_APP",
  MANAGER_APP = "MANAGER_APP",
}

/**
 * Type of app users segment
 */
export enum AppSegmentType {
  BODYSHOPS = "BODYSHOPS",
  FLEETS = "FLEETS",
}

/**
 * Type of the app feature
 */
export enum AppStateType {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
  DISABLED_APP = "DISABLED_APP",
}

export enum AppointmentType {
  CAR_HANDOVER = "CAR_HANDOVER",
  INSPECTION_ON_SITE = "INSPECTION_ON_SITE",
}

/**
 * Position for the car part on the car.
 */
export enum Area {
  BACK = "BACK",
  CABIN = "CABIN",
  DRIVER_SIDE = "DRIVER_SIDE",
  FRONT = "FRONT",
  MECHANICAL = "MECHANICAL",
  MIDDLE = "MIDDLE",
  MISSING = "MISSING",
  OTHER = "OTHER",
  PASSENGER_SIDE = "PASSENGER_SIDE",
  WHEEL_LEFT_FRONT = "WHEEL_LEFT_FRONT",
  WHEEL_LEFT_REAR = "WHEEL_LEFT_REAR",
  WHEEL_RIGHT_FRONT = "WHEEL_RIGHT_FRONT",
  WHEEL_RIGHT_REAR = "WHEEL_RIGHT_REAR",
}

export enum BodyStyle {
  COMBI = "COMBI",
  COMPACT = "COMPACT",
  COMPACT_E = "COMPACT_E",
  CONVERTIBLE = "CONVERTIBLE",
  FASTBACK = "FASTBACK",
  HACKNEY = "HACKNEY",
  HATCHBACK = "HATCHBACK",
  LIGHT_TRUCK = "LIGHT_TRUCK",
  MINIVAN = "MINIVAN",
  OFF_ROAD = "OFF_ROAD",
  OTHER = "OTHER",
  ROADSTER = "ROADSTER",
  SEDAN = "SEDAN",
  STATION_WAGON = "STATION_WAGON",
  SUBCOMPACT = "SUBCOMPACT",
  SUV = "SUV",
  TRANSPORTER = "TRANSPORTER",
  VAN = "VAN",
}

export enum CarAvailabilityStatus {
  AVAILABLE = "AVAILABLE",
  PARTIALLY_AVAILABLE = "PARTIALLY_AVAILABLE",
  UNAVAILABLE = "UNAVAILABLE",
}

/**
 * Type of the car part.
 */
export enum CarPartType {
  AIR_CONDITIONING_AND_HEATING = "AIR_CONDITIONING_AND_HEATING",
  A_PILLAR_LEFT = "A_PILLAR_LEFT",
  A_PILLAR_LEFT_1 = "A_PILLAR_LEFT_1",
  A_PILLAR_LEFT_2 = "A_PILLAR_LEFT_2",
  A_PILLAR_RIGHT = "A_PILLAR_RIGHT",
  A_PILLAR_RIGHT_1 = "A_PILLAR_RIGHT_1",
  A_PILLAR_RIGHT_2 = "A_PILLAR_RIGHT_2",
  BODY_SILL_LEFT_FRONT = "BODY_SILL_LEFT_FRONT",
  BODY_SILL_LEFT_REAR = "BODY_SILL_LEFT_REAR",
  BODY_SILL_RIGHT_FRONT = "BODY_SILL_RIGHT_FRONT",
  BODY_SILL_RIGHT_REAR = "BODY_SILL_RIGHT_REAR",
  BRAKES = "BRAKES",
  BRAKE_LIGHT_LEFT_REAR = "BRAKE_LIGHT_LEFT_REAR",
  BRAKE_LIGHT_RIGHT_REAR = "BRAKE_LIGHT_RIGHT_REAR",
  BUMPER_CENTER_FRONT = "BUMPER_CENTER_FRONT",
  BUMPER_CENTER_REAR = "BUMPER_CENTER_REAR",
  BUMPER_LEFT_FRONT = "BUMPER_LEFT_FRONT",
  BUMPER_LEFT_REAR = "BUMPER_LEFT_REAR",
  BUMPER_REAR_1 = "BUMPER_REAR_1",
  BUMPER_REAR_2 = "BUMPER_REAR_2",
  BUMPER_REAR_3 = "BUMPER_REAR_3",
  BUMPER_REAR_5 = "BUMPER_REAR_5",
  BUMPER_RIGHT_FRONT = "BUMPER_RIGHT_FRONT",
  BUMPER_RIGHT_REAR = "BUMPER_RIGHT_REAR",
  BUMPER_SIDE_LEFT_FRONT = "BUMPER_SIDE_LEFT_FRONT",
  BUMPER_SIDE_LEFT_REAR = "BUMPER_SIDE_LEFT_REAR",
  BUMPER_SIDE_RIGHT_FRONT = "BUMPER_SIDE_RIGHT_FRONT",
  BUMPER_SIDE_RIGHT_REAR = "BUMPER_SIDE_RIGHT_REAR",
  B_PILLAR_LEFT = "B_PILLAR_LEFT",
  B_PILLAR_LEFT_1 = "B_PILLAR_LEFT_1",
  B_PILLAR_LEFT_2 = "B_PILLAR_LEFT_2",
  B_PILLAR_RIGHT = "B_PILLAR_RIGHT",
  B_PILLAR_RIGHT_1 = "B_PILLAR_RIGHT_1",
  B_PILLAR_RIGHT_2 = "B_PILLAR_RIGHT_2",
  CARGO_BOX_SIDE_LEFT = "CARGO_BOX_SIDE_LEFT",
  CARGO_BOX_SIDE_RIGHT = "CARGO_BOX_SIDE_RIGHT",
  CARGO_LEFT_REAR_1 = "CARGO_LEFT_REAR_1",
  CARGO_LEFT_REAR_2 = "CARGO_LEFT_REAR_2",
  CARGO_LEFT_REAR_3 = "CARGO_LEFT_REAR_3",
  CARGO_LEFT_REAR_4 = "CARGO_LEFT_REAR_4",
  CARGO_RIGHT_REAR_1 = "CARGO_RIGHT_REAR_1",
  CARGO_RIGHT_REAR_2 = "CARGO_RIGHT_REAR_2",
  CARGO_RIGHT_REAR_3 = "CARGO_RIGHT_REAR_3",
  CARGO_RIGHT_REAR_4 = "CARGO_RIGHT_REAR_4",
  CHASSIS_AND_STEERING = "CHASSIS_AND_STEERING",
  CONDENSER = "CONDENSER",
  CONSOLE = "CONSOLE",
  C_PILLAR_LEFT = "C_PILLAR_LEFT",
  C_PILLAR_RIGHT = "C_PILLAR_RIGHT",
  DOOR = "DOOR",
  DOOR_HANDLE_LEFT_FRONT = "DOOR_HANDLE_LEFT_FRONT",
  DOOR_HANDLE_LEFT_REAR = "DOOR_HANDLE_LEFT_REAR",
  DOOR_HANDLE_REAR = "DOOR_HANDLE_REAR",
  DOOR_HANDLE_RIGHT_FRONT = "DOOR_HANDLE_RIGHT_FRONT",
  DOOR_HANDLE_RIGHT_REAR = "DOOR_HANDLE_RIGHT_REAR",
  DOOR_LEFT_FRONT = "DOOR_LEFT_FRONT",
  DOOR_LEFT_FRONT_1 = "DOOR_LEFT_FRONT_1",
  DOOR_LEFT_FRONT_2 = "DOOR_LEFT_FRONT_2",
  DOOR_LEFT_FRONT_3 = "DOOR_LEFT_FRONT_3",
  DOOR_LEFT_FRONT_4 = "DOOR_LEFT_FRONT_4",
  DOOR_LEFT_REAR = "DOOR_LEFT_REAR",
  DOOR_LEFT_REAR_1 = "DOOR_LEFT_REAR_1",
  DOOR_LEFT_REAR_2 = "DOOR_LEFT_REAR_2",
  DOOR_LEFT_REAR_3 = "DOOR_LEFT_REAR_3",
  DOOR_LEFT_REAR_4 = "DOOR_LEFT_REAR_4",
  DOOR_QUARTER_LEFT_FRONT = "DOOR_QUARTER_LEFT_FRONT",
  DOOR_QUARTER_LEFT_REAR = "DOOR_QUARTER_LEFT_REAR",
  DOOR_QUARTER_LEFT_REAR_1 = "DOOR_QUARTER_LEFT_REAR_1",
  DOOR_QUARTER_LEFT_REAR_2 = "DOOR_QUARTER_LEFT_REAR_2",
  DOOR_QUARTER_RIGHT_FRONT = "DOOR_QUARTER_RIGHT_FRONT",
  DOOR_QUARTER_RIGHT_REAR = "DOOR_QUARTER_RIGHT_REAR",
  DOOR_QUARTER_RIGHT_REAR_1 = "DOOR_QUARTER_RIGHT_REAR_1",
  DOOR_QUARTER_RIGHT_REAR_2 = "DOOR_QUARTER_RIGHT_REAR_2",
  DOOR_RIGHT_FRONT = "DOOR_RIGHT_FRONT",
  DOOR_RIGHT_FRONT_1 = "DOOR_RIGHT_FRONT_1",
  DOOR_RIGHT_FRONT_2 = "DOOR_RIGHT_FRONT_2",
  DOOR_RIGHT_FRONT_3 = "DOOR_RIGHT_FRONT_3",
  DOOR_RIGHT_FRONT_4 = "DOOR_RIGHT_FRONT_4",
  DOOR_RIGHT_REAR = "DOOR_RIGHT_REAR",
  DOOR_RIGHT_REAR_1 = "DOOR_RIGHT_REAR_1",
  DOOR_RIGHT_REAR_2 = "DOOR_RIGHT_REAR_2",
  DOOR_RIGHT_REAR_3 = "DOOR_RIGHT_REAR_3",
  DOOR_RIGHT_REAR_4 = "DOOR_RIGHT_REAR_4",
  D_PILLAR_LEFT = "D_PILLAR_LEFT",
  D_PILLAR_RIGHT = "D_PILLAR_RIGHT",
  ELECTRONICS = "ELECTRONICS",
  ENGINE = "ENGINE",
  FENDER_LEFT_FRONT = "FENDER_LEFT_FRONT",
  FENDER_LEFT_FRONT_1 = "FENDER_LEFT_FRONT_1",
  FENDER_LEFT_FRONT_2 = "FENDER_LEFT_FRONT_2",
  FENDER_LEFT_REAR = "FENDER_LEFT_REAR",
  FENDER_LEFT_REAR_1 = "FENDER_LEFT_REAR_1",
  FENDER_LEFT_REAR_2 = "FENDER_LEFT_REAR_2",
  FENDER_LEFT_REAR_3 = "FENDER_LEFT_REAR_3",
  FENDER_LEFT_REAR_4 = "FENDER_LEFT_REAR_4",
  FENDER_RIGHT_FRONT = "FENDER_RIGHT_FRONT",
  FENDER_RIGHT_FRONT_1 = "FENDER_RIGHT_FRONT_1",
  FENDER_RIGHT_FRONT_2 = "FENDER_RIGHT_FRONT_2",
  FENDER_RIGHT_REAR = "FENDER_RIGHT_REAR",
  FENDER_RIGHT_REAR_1 = "FENDER_RIGHT_REAR_1",
  FENDER_RIGHT_REAR_2 = "FENDER_RIGHT_REAR_2",
  FENDER_RIGHT_REAR_3 = "FENDER_RIGHT_REAR_3",
  FENDER_RIGHT_REAR_4 = "FENDER_RIGHT_REAR_4",
  FLOOR = "FLOOR",
  FOG_LIGHT_LEFT_FRONT = "FOG_LIGHT_LEFT_FRONT",
  FOG_LIGHT_RIGHT_FRONT = "FOG_LIGHT_RIGHT_FRONT",
  FRONT_PANEL = "FRONT_PANEL",
  FUEL_FILTER_CAP_COVER = "FUEL_FILTER_CAP_COVER",
  HEADLIGHTS_LEFT = "HEADLIGHTS_LEFT",
  HEADLIGHTS_RIGHT = "HEADLIGHTS_RIGHT",
  HOOD = "HOOD",
  HOOD_1 = "HOOD_1",
  HOOD_2 = "HOOD_2",
  HOOD_3 = "HOOD_3",
  HOOD_4 = "HOOD_4",
  LICENSE_PLATE_HOLDER_FRONT = "LICENSE_PLATE_HOLDER_FRONT",
  LICENSE_PLATE_HOLDER_REAR = "LICENSE_PLATE_HOLDER_REAR",
  LOGO_FRONT = "LOGO_FRONT",
  LOGO_REAR = "LOGO_REAR",
  MAINTENANCE = "MAINTENANCE",
  MIRROR_LEFT = "MIRROR_LEFT",
  MIRROR_RIGHT = "MIRROR_RIGHT",
  MISSING = "MISSING",
  OTHER = "OTHER",
  PANELS_AND_COVERS = "PANELS_AND_COVERS",
  PANEL_LEFT_REAR = "PANEL_LEFT_REAR",
  PANEL_RIGHT_REAR = "PANEL_RIGHT_REAR",
  QUARTER_PANEL_LEFT_REAR = "QUARTER_PANEL_LEFT_REAR",
  QUARTER_PANEL_RIGHT_REAR = "QUARTER_PANEL_RIGHT_REAR",
  RADIATOR_GRILLE_FRONT = "RADIATOR_GRILLE_FRONT",
  RIM_LEFT_FRONT = "RIM_LEFT_FRONT",
  RIM_LEFT_REAR = "RIM_LEFT_REAR",
  RIM_RIGHT_FRONT = "RIM_RIGHT_FRONT",
  RIM_RIGHT_REAR = "RIM_RIGHT_REAR",
  ROOF = "ROOF",
  ROOF_1 = "ROOF_1",
  ROOF_2 = "ROOF_2",
  ROOF_3 = "ROOF_3",
  ROOF_4 = "ROOF_4",
  ROOF_5 = "ROOF_5",
  ROOF_ANTENNA = "ROOF_ANTENNA",
  ROOF_RAIL_LEFT = "ROOF_RAIL_LEFT",
  ROOF_RAIL_RIGHT = "ROOF_RAIL_RIGHT",
  SEATS = "SEATS",
  SIDE_MARKER_LIGHTS_LEFT = "SIDE_MARKER_LIGHTS_LEFT",
  SIDE_MARKER_LIGHTS_RIGHT = "SIDE_MARKER_LIGHTS_RIGHT",
  STEP_REAR = "STEP_REAR",
  SUNROOF = "SUNROOF",
  SUNROOF_1 = "SUNROOF_1",
  SUNROOF_2 = "SUNROOF_2",
  TAILGATE = "TAILGATE",
  TAILGATE_1 = "TAILGATE_1",
  TAILGATE_2 = "TAILGATE_2",
  TAILGATE_3 = "TAILGATE_3",
  TAILGATE_4 = "TAILGATE_4",
  TAILGATE_DOOR_HANDLE_REAR = "TAILGATE_DOOR_HANDLE_REAR",
  TAILLIGHTS_LEFT = "TAILLIGHTS_LEFT",
  TAILLIGHTS_RIGHT = "TAILLIGHTS_RIGHT",
  TIRE_LEFT_FRONT = "TIRE_LEFT_FRONT",
  TIRE_LEFT_REAR = "TIRE_LEFT_REAR",
  TIRE_RIGHT_FRONT = "TIRE_RIGHT_FRONT",
  TIRE_RIGHT_REAR = "TIRE_RIGHT_REAR",
  TRUNK = "TRUNK",
  TRUNK_LEFT = "TRUNK_LEFT",
  TRUNK_RIGHT = "TRUNK_RIGHT",
  TYRES = "TYRES",
  WHEEL_LEFT_FRONT = "WHEEL_LEFT_FRONT",
  WHEEL_LEFT_REAR = "WHEEL_LEFT_REAR",
  WHEEL_RIGHT_FRONT = "WHEEL_RIGHT_FRONT",
  WHEEL_RIGHT_REAR = "WHEEL_RIGHT_REAR",
  WINDOW_LEFT_FRONT = "WINDOW_LEFT_FRONT",
  WINDOW_LEFT_REAR = "WINDOW_LEFT_REAR",
  WINDOW_REAR = "WINDOW_REAR",
  WINDOW_REAR_1 = "WINDOW_REAR_1",
  WINDOW_REAR_2 = "WINDOW_REAR_2",
  WINDOW_RIGHT_FRONT = "WINDOW_RIGHT_FRONT",
  WINDOW_RIGHT_REAR = "WINDOW_RIGHT_REAR",
  WINDSHIELD_FRONT = "WINDSHIELD_FRONT",
  WINDSHIELD_FRONT_1 = "WINDSHIELD_FRONT_1",
  WINDSHIELD_FRONT_2 = "WINDSHIELD_FRONT_2",
  WINDSHIELD_FRONT_3 = "WINDSHIELD_FRONT_3",
  WINDSHIELD_FRONT_4 = "WINDSHIELD_FRONT_4",
}

export enum CarsSearchBy {
  LicensePlate = "LicensePlate",
  PartnerID = "PartnerID",
  VIN = "VIN",
}

export enum CarsSortByList {
  FirstRegistration = "FirstRegistration",
  FleetName = "FleetName",
  LicensePlate = "LicensePlate",
  Location = "Location",
  MileageReportedDate = "MileageReportedDate",
  Model = "Model",
  VIN = "VIN",
}

/**
 * Type of the damage category.
 */
export enum CategoryType {
  CONSOLE = "CONSOLE",
  GLASS = "GLASS",
  INTERIOR = "INTERIOR",
  LIGHTING = "LIGHTING",
  MECHANICAL = "MECHANICAL",
  MIRROR = "MIRROR",
  MISSING = "MISSING",
  MISSING_PARTS = "MISSING_PARTS",
  ODOUR = "ODOUR",
  PAINT = "PAINT",
  PANELS_AND_COVERS = "PANELS_AND_COVERS",
  PILLAR = "PILLAR",
  SERVICE_LIGHTS = "SERVICE_LIGHTS",
  TRUNK_INTERIOR = "TRUNK_INTERIOR",
  WHEELS = "WHEELS",
}

/**
 * Type of a checklist template
 */
export enum ChecklistTemplateType {
  BASELINE = "BASELINE",
  REGULAR = "REGULAR",
}

export enum CompleteDamageReportVerificationStatus {
  internally_repaired = "internally_repaired",
  new = "new",
  repair_postponed = "repair_postponed",
}

export enum DamageCategoryType {
  ABRASIONS = "ABRASIONS",
  BURN_HOLES = "BURN_HOLES",
  CIGARETTE_SMOKE_ODOUR = "CIGARETTE_SMOKE_ODOUR",
  CRACK = "CRACK",
  DAMAGED_TRUNK_SILL = "DAMAGED_TRUNK_SILL",
  DENT = "DENT",
  DIRTY_SEAT_BELTS = "DIRTY_SEAT_BELTS",
  DIRT_AND_STAINS = "DIRT_AND_STAINS",
  GLASS = "GLASS",
  HAIL = "HAIL",
  HOLES = "HOLES",
  LIGHT = "LIGHT",
  MATERIAL_DAMAGED = "MATERIAL_DAMAGED",
  MECHANICAL = "MECHANICAL",
  MIRROR = "MIRROR",
  MISSING_PARTS = "MISSING_PARTS",
  ODOUR = "ODOUR",
  OLD_SEATS = "OLD_SEATS",
  OTHER = "OTHER",
  PETS_ODOUR = "PETS_ODOUR",
  RED_ABS = "RED_ABS",
  RED_AIRBAGS = "RED_AIRBAGS",
  RED_BATTERY = "RED_BATTERY",
  RED_BRAKE_SYSTEM = "RED_BRAKE_SYSTEM",
  RED_BRAKE_WEAR = "RED_BRAKE_WEAR",
  RED_COOLANT_TEMPERATURE = "RED_COOLANT_TEMPERATURE",
  RED_MOTOR_CONTROL = "RED_MOTOR_CONTROL",
  RED_OIL_LEVEL = "RED_OIL_LEVEL",
  RED_TYRE_PRESSURE = "RED_TYRE_PRESSURE",
  RIMS_TIRES = "RIMS_TIRES",
  SCRATCH = "SCRATCH",
  SCRATCHES = "SCRATCHES",
  SOILING_AND_STAINGS = "SOILING_AND_STAINGS",
  STONE_CHIPS = "STONE_CHIPS",
  VISIBLE_COLOR_CHANGE = "VISIBLE_COLOR_CHANGE",
  YELLOW_ABS = "YELLOW_ABS",
  YELLOW_BATTERY = "YELLOW_BATTERY",
  YELLOW_BRAKE_SYSTEM = "YELLOW_BRAKE_SYSTEM",
  YELLOW_BRAKE_WEAR = "YELLOW_BRAKE_WEAR",
  YELLOW_INSPECTION_IS_DUE = "YELLOW_INSPECTION_IS_DUE",
  YELLOW_MOTOR_CONTROL = "YELLOW_MOTOR_CONTROL",
  YELLOW_OIL_LEVEL = "YELLOW_OIL_LEVEL",
  YELLOW_TYRE_PRESSURE = "YELLOW_TYRE_PRESSURE",
}

/**
 * DamageReportDrivableType
 */
export enum DamageReportDrivableType {
  NO = "NO",
  UNKNOWN = "UNKNOWN",
  YES = "YES",
}

/**
 * List of statuses that could be used for damage list filtering
 */
export enum DamageReportFilteringStatus {
  CLOSED = "CLOSED",
  DAMAGE_REQUEST_RECEIVED = "DAMAGE_REQUEST_RECEIVED",
  FLEET_MANAGER_APPROVAL_RECEIVED = "FLEET_MANAGER_APPROVAL_RECEIVED",
  FLEET_MANAGER_APPROVAL_REJECTED = "FLEET_MANAGER_APPROVAL_REJECTED",
  FLEET_MANAGER_APPROVAL_REQUESTED = "FLEET_MANAGER_APPROVAL_REQUESTED",
  INSPECTION_ON_SITE_APPROVED = "INSPECTION_ON_SITE_APPROVED",
  INSPECTION_REJECTED = "INSPECTION_REJECTED",
  INSPECTION_REQUESTED = "INSPECTION_REQUESTED",
  INSPECTION_SCHEDULED = "INSPECTION_SCHEDULED",
  INSURANCE_APPROVAL_RECEIVED = "INSURANCE_APPROVAL_RECEIVED",
  INSURANCE_APPROVAL_REQUESTED = "INSURANCE_APPROVAL_REQUESTED",
  INTERNALLY_REPAIRED = "INTERNALLY_REPAIRED",
  INVOICE_SETTLED = "INVOICE_SETTLED",
  INVOICE_UPLOADED = "INVOICE_UPLOADED",
  IN_RECOGNITION = "IN_RECOGNITION",
  LEASING_APPROVAL_RECEIVED = "LEASING_APPROVAL_RECEIVED",
  LEASING_APPROVAL_REQUESTED = "LEASING_APPROVAL_REQUESTED",
  LINK_SENT = "LINK_SENT",
  NEW = "NEW",
  REPAIR_APPOINTMENT_SCHEDULED = "REPAIR_APPOINTMENT_SCHEDULED",
  REPAIR_APPOINTMENT_SUGGESTED = "REPAIR_APPOINTMENT_SUGGESTED",
  REPAIR_ESTIMATED = "REPAIR_ESTIMATED",
  REPAIR_FINISHED = "REPAIR_FINISHED",
  REPAIR_OFFER_ADJUSTED = "REPAIR_OFFER_ADJUSTED",
  REPAIR_POSTPONED = "REPAIR_POSTPONED",
  REPAIR_REJECTED = "REPAIR_REJECTED",
  REPAIR_REQUESTED = "REPAIR_REQUESTED",
  REPAIR_STARTED = "REPAIR_STARTED",
  SERVICE_PROVIDER_ASSIGNED = "SERVICE_PROVIDER_ASSIGNED",
  TO_REVIEW = "TO_REVIEW",
}

export enum DamageReportFilteringType {
  ACCIDENT = "ACCIDENT",
  COMPOUND = "COMPOUND",
  EXTERIOR = "EXTERIOR",
  INTERIOR = "INTERIOR",
  MECHANICAL = "MECHANICAL",
  NON_ACCIDENT = "NON_ACCIDENT",
  PRE_INSPECTION = "PRE_INSPECTION",
  SERVICE_LIGHTS = "SERVICE_LIGHTS",
  TELEMATIC = "TELEMATIC",
  THIRD_PARTY = "THIRD_PARTY",
}

/**
 * Possible damage report image types.
 */
export enum DamageReportImageType {
  ACCIDENT_DOCUMENT = "ACCIDENT_DOCUMENT",
  ACCIDENT_REPORT = "ACCIDENT_REPORT",
  ACCIDENT_SCENE = "ACCIDENT_SCENE",
  ADDITIONAL = "ADDITIONAL",
  CLOSE_UP = "CLOSE_UP",
  DASHBOARD = "DASHBOARD",
  FRONT_LEFT_ANGLE = "FRONT_LEFT_ANGLE",
  INSPECTION = "INSPECTION",
  INTERIOR = "INTERIOR",
  INTERIOR_LEFT = "INTERIOR_LEFT",
  INTERIOR_RIGHT = "INTERIOR_RIGHT",
  LEFT_DIAGONAL_VIEW = "LEFT_DIAGONAL_VIEW",
  OTHER_PARTY_DRIVER_LICENSE = "OTHER_PARTY_DRIVER_LICENSE",
  OTHER_PARTY_VEHICLE = "OTHER_PARTY_VEHICLE",
  POLICE_REPORT = "POLICE_REPORT",
  REAR_RIGHT_ANGLE = "REAR_RIGHT_ANGLE",
  REAR_SIDE_GENERAL = "REAR_SIDE_GENERAL",
  REGISTRATION_DOCUMENT = "REGISTRATION_DOCUMENT",
  RIGHT_DIAGONAL_VIEW = "RIGHT_DIAGONAL_VIEW",
  SILHOUETTE_OF_CAR = "SILHOUETTE_OF_CAR",
  UPLOADED = "UPLOADED",
  VIEW_OF_DAMAGE = "VIEW_OF_DAMAGE",
}


/**
 * List of statues of damage report
 */
export enum DamageReportStatus {
  CLOSED = "CLOSED",
  DAMAGE_REQUEST_RECEIVED = "DAMAGE_REQUEST_RECEIVED",
  INDICATED = "INDICATED",
  INTERNALLY_REPAIRED = "INTERNALLY_REPAIRED",
  IN_RECOGNITION = "IN_RECOGNITION",
  IN_REPAIR = "IN_REPAIR",
  IN_SETTLEMENT = "IN_SETTLEMENT",
  LINK_GENERATED = "LINK_GENERATED",
  LINK_SENT = "LINK_SENT",
  NEW = "NEW",
  REPAIRED = "REPAIRED",
  REPAIR_POSTPONED = "REPAIR_POSTPONED",
  REPAIR_REQUESTED = "REPAIR_REQUESTED",
  SETTLED = "SETTLED",
  TO_REVIEW = "TO_REVIEW",
}

/**
 * List of damage report statuses, which are eligible to be updated to
 */
export enum DamageReportStatusAllowedForUpdate {
  CLOSED = "CLOSED",
  INTERNALLY_REPAIRED = "INTERNALLY_REPAIRED",
  IN_REPAIR = "IN_REPAIR",
  NEW = "NEW",
  REPAIR_POSTPONED = "REPAIR_POSTPONED",
  REPAIR_REQUESTED = "REPAIR_REQUESTED",
}

export enum DamageReportType {
  COMPOUND = "COMPOUND",
  EXTERIOR = "EXTERIOR",
  INTERIOR = "INTERIOR",
  MECHANICAL = "MECHANICAL",
  PRE_INSPECTION = "PRE_INSPECTION",
  SERVICE_LIGHTS = "SERVICE_LIGHTS",
  TELEMATIC = "TELEMATIC",
  THIRD_PARTY = "THIRD_PARTY",
}

/**
 * The source of damage report. Does not necessarily directly map to the application name.
 */
export enum DataSource {
  BODYSHOP_APPLICATION = "BODYSHOP_APPLICATION",
  DRIVER_APPLICATION = "DRIVER_APPLICATION",
  FLEET_MANAGER_APPLICATION = "FLEET_MANAGER_APPLICATION",
}

/**
 * Define the driver type: steered, fleet, private, etc.
 */
export enum DriverType {
  FLEET_DRIVER = "FLEET_DRIVER",
  PRIVATE_DRIVER = "PRIVATE_DRIVER",
  STEERED_DRIVER = "STEERED_DRIVER",
}

export enum FleetProductPackages {
  AI = "AI",
  CHECKLIST = "CHECKLIST",
  DAMAGE = "DAMAGE",
  FULL_SERVICE = "FULL_SERVICE",
  LEASING = "LEASING",
  SOFTWARE = "SOFTWARE",
}

export enum FleetSegment {
  DELIVERY = "DELIVERY",
  MOBILITY = "MOBILITY",
  MOTIVATIONAL = "MOTIVATIONAL",
  SERVICE = "SERVICE",
}

export enum FleetStatus {
  ACTIVE = "ACTIVE",
  CHURNED = "CHURNED",
  TRIAL = "TRIAL",
}

/**
 * Type of the legal agreement
 */
export enum LegalAgreementType {
  CANCELLATION_POLICY = "CANCELLATION_POLICY",
  SERVICE_AGREEMENT = "SERVICE_AGREEMENT",
  TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS",
}

/**
 * Car part types related to mechanical damage report
 */
export enum MechanicalCarPart {
  AIR_CONDITIONING_AND_HEATING = "AIR_CONDITIONING_AND_HEATING",
  BRAKES = "BRAKES",
  CHASSIS_AND_STEERING = "CHASSIS_AND_STEERING",
  ELECTRONICS = "ELECTRONICS",
  ENGINE = "ENGINE",
  MAINTENANCE = "MAINTENANCE",
  OTHER = "OTHER",
  TYRES = "TYRES",
}

/**
 * Damage categories related to mechanical damage report
 */
export enum MechanicalDamageCategory {
  MECHANICAL = "MECHANICAL",
}

/**
 * Type of the preInspection damage reason
 */
export enum PreInspectionDamageReasons {
  ANIMAL_COLLISION = "ANIMAL_COLLISION",
  CAR_COLLISION = "CAR_COLLISION",
  LANE_CHANGE = "LANE_CHANGE",
  OBJECT_COLLISION = "OBJECT_COLLISION",
  OTHER = "OTHER",
  OVERHAUL = "OVERHAUL",
  PARKED_CAR_COLLISION = "PARKED_CAR_COLLISION",
  PARKING_DAMAGE = "PARKING_DAMAGE",
  REAR_END_COLLISION = "REAR_END_COLLISION",
  RIGHT_OF_WAY_VIOLATION = "RIGHT_OF_WAY_VIOLATION",
  ROADWAY_AGREEMENT = "ROADWAY_AGREEMENT",
  STONE_CHIP = "STONE_CHIP",
  WEATHER_DAMAGE = "WEATHER_DAMAGE",
}

export enum ReportFilterSearchKeysType {
  ASSIGNEE = "ASSIGNEE",
  BODYSHOP = "BODYSHOP",
  CAR = "CAR",
  DAMAGE_REPORT = "DAMAGE_REPORT",
  FLEET = "FLEET",
  LOCATION = "LOCATION",
  ROADWORTHY = "ROADWORTHY",
  STATUS = "STATUS",
  STEERER = "STEERER",
  TAGS = "TAGS",
}

export enum ReportsSortBy {
  BodyshopName = "BodyshopName",
  CarLicensePlate = "CarLicensePlate",
  CarMake = "CarMake",
  CarModel = "CarModel",
  CreatedAt = "CreatedAt",
  DamageNumber = "DamageNumber",
  FleetName = "FleetName",
  Location = "Location",
  ReportType = "ReportType",
  ReportedBy = "ReportedBy",
  Status = "Status",
  SteererName = "SteererName",
}

/**
 * Define signed document type:
 */
export enum SignedDocumentType {
  ORDER_INFORMATION = "ORDER_INFORMATION",
  OTHER = "OTHER",
  REPAIR_COST_COVERAGE = "REPAIR_COST_COVERAGE",
  SERVICE_AGREEMENT = "SERVICE_AGREEMENT",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SortOrderList {
  ASC = "ASC",
  DESC = "DESC",
}

export enum UserRole {
  BODYSHOP_ACCEPTANCE_MASTER = "BODYSHOP_ACCEPTANCE_MASTER",
  BODYSHOP_CUSTOMER_CARE = "BODYSHOP_CUSTOMER_CARE",
  BODYSHOP_GROUP_MANAGER = "BODYSHOP_GROUP_MANAGER",
  BODYSHOP_MANAGER = "BODYSHOP_MANAGER",
  CAR_DEALER = "CAR_DEALER",
  DAMAGE_STEERER = "DAMAGE_STEERER",
  DRIVER = "DRIVER",
  EXTERNAL_OPERATIONS = "EXTERNAL_OPERATIONS",
  FLEET_MANAGER = "FLEET_MANAGER",
  GUEST = "GUEST",
  LOCAL_FLEET_MANAGER = "LOCAL_FLEET_MANAGER",
  REGIONAL_FLEET_MANAGER = "REGIONAL_FLEET_MANAGER",
  RF_ACCESSOR = "RF_ACCESSOR",
  RF_OPERATIONS = "RF_OPERATIONS",
  TECHNICAL_EXPERT = "TECHNICAL_EXPERT",
}

export interface AcceptLegalAgreement {
  type: LegalAgreementType;
  isAccepted: boolean;
}

export interface AccidentInfoInput {
  datetime: any;
  address: string;
  isCarRoadworthy?: DamageReportDrivableType | null;
  desiredRepairAddress?: string | null;
}

export interface AccidentThirdPartyInput {
  licensePlate: string;
  personName?: string | null;
  insurance?: string | null;
}

export interface AdditionalInformation {
  mileage?: string | null;
  fleetComment?: string | null;
  note?: string | null;
}

export interface AdditionalServiceItem {
  id: string;
  isSelected: boolean;
}

export interface AppointmentSlotInput {
  date: any;
}

export interface AttachedDamageImage {
  id: string;
  isVisibilityRestricted?: boolean | null;
}

export interface CarInfoInput {
  make?: string | null;
  model?: string | null;
  location?: string | null;
  comment?: string | null;
  licensePlate: string;
  vin?: string | null;
  firstRegistration?: any | null;
  carRegistrationCompany?: string | null;
  bodyType: BodyStyle;
  insuranceCompany: string;
  insurancePolicyNumber?: string | null;
}

export interface ChecklistReportCheckDamageInput {
  id: string;
}

export interface ChecklistReportCheckInput {
  name: string;
  data?: any | null;
  damageReports?: ChecklistReportCheckDamageInput[] | null;
}

export interface ChecklistReportImageInput {
  id?: string | null;
  file: any;
  type: string;
}

export interface CloseIndicatedDamageReportInput {
  reporterName?: string | null;
  reporterEmail?: string | null;
  reporterPhone?: string | null;
  message?: string | null;
}

export interface CompleteAccidentReportInput {
  carParts: CarPartType[];
  damageDescription?: string | null;
  accidentThirdParty: AccidentThirdPartyInput;
  accidentInfo: AccidentInfoInput;
  damageReasons: DamageReasonsInput;
  accidentResponsibility?: AccidentResponsibilityCauser | null;
  damageImagesIds: string[];
  policeReport: PoliceReportInput;
  driverInformation: DriverInformationInput;
  damageCategoryTypes?: DamageCategoryType[] | null;
  reportedBy?: string | null;
  accidentQuestions?: UpdateAccidentQuestionsInput | null;
  driverInformConsent?: boolean | null;
}

export interface CompleteDamageReportVerificationInput {
  damageCategories: DamageCategoryType[];
  carParts: CarPartType[];
  publishImages: string[];
  isConfirmed: boolean;
  message?: string | null;
  nextStatus?: CompleteDamageReportVerificationStatus | null;
  fleetComment?: string | null;
}

export interface CompleteExteriorDamageReportInput {
  damageCategoryTypes: DamageCategoryType[];
  additionalInformation?: AdditionalInformation | null;
  driverInformation?: DriverInformation | null;
  carParts: CarPartType[];
  attachedDamageImages?: AttachedDamageImage[] | null;
  reporterName?: string | null;
  isMinor?: boolean | null;
  status?: DamageReportStatus | null;
}

export interface CompleteIndicatedDamageReportInput {
  damageCategories: DamageCategoryType[];
  carParts: CarPartType[];
  publishImages: string[];
  reporterName?: string | null;
  reporterEmail?: string | null;
  reporterPhone?: string | null;
  message?: string | null;
}

export interface CompleteInteriorDamageReportInput {
  driverName?: string | null;
  driverEmail?: string | null;
  driverPhone?: string | null;
  driverCity?: string | null;
  driverAddress?: string | null;
  driverZip?: string | null;
  damageLocation?: CarPartType[] | null;
  additionalInformation?: string | null;
  damageCategory: DamageCategoryType;
  imagesIds?: string[] | null;
  reporterName?: string | null;
}

export interface CompleteMechanicalDamageReportInput {
  driverName?: string | null;
  driverEmail?: string | null;
  driverPhone?: string | null;
  driverCity?: string | null;
  driverAddress?: string | null;
  driverZip?: string | null;
  damageLocation: MechanicalCarPart[];
  damageCategory: MechanicalDamageCategory;
  imageId?: string | null;
  problemDescription?: string | null;
  desiredRepairAddress?: string | null;
}

export interface CompletePreInspectionReportInput {
  bodyType: BodyStyle;
  carParts: CarPartType[];
  damageReasons: DamageReasons;
  additionalServices: AdditionalServiceItem[];
  registrationData?: UpdateRegistrationData | null;
  legalAgreements: AcceptLegalAgreement[];
  damageImagesIds: string[];
  slots: AppointmentSlotInput[];
}

export interface CompleteSimpleAccidentReportInput {
  carParts: CarPartType[];
  damageCategoryTypes: DamageCategoryType[];
  damageImagesIds: string[];
  damageDescription?: string | null;
}

export interface CompleteThirdPartyReportInput {
  carPartTypes: CarPartType[];
  damageInformation: DamageInfoInput;
  fleetDriverInfo: FleetDriverInfoInput;
  driverInfo: DriverInfoInput;
  carInfo: CarInfoInput;
  damageCategoryTypes: DamageCategoryType[];
}

export interface DamageInfoInput {
  damageDate: any;
  damageAddress: string;
}

export interface DamageReasons {
  damageReasons?: PreInspectionDamageReasons[] | null;
  damageDescription?: string | null;
}

export interface DamageReasonsInput {
  damageReasons?: AccidentDamageReasons[] | null;
  damageReasonComment?: string | null;
}

export interface DamageReportsInput {
  currentStepName?: string | null;
}

export interface DamageReportsListFilterArgs {
  type?: DamageReportFilteringType[] | null;
  status?: DamageReportFilteringStatus[] | null;
  location?: string[] | null;
  make?: string[] | null;
  model?: string[] | null;
  damageArea?: string[] | null;
  damageType?: string[] | null;
  fromDate?: any | null;
  toDate?: any | null;
  bodyshop?: string[] | null;
  fleet?: string[] | null;
  roadworthy?: DamageReportDrivableType[] | null;
  steerer?: string[] | null;
  assignee?: string[] | null;
  tags?: string[] | null;
  includeArchived?: boolean | null;
  checklistReportId?: string | null;
  carId?: string | null;
  q?: string | null;
  page?: number | null;
  limit?: number | null;
  sortBy?: ReportsSortBy | null;
  sortOrder?: SortOrder | null;
}

export interface DriverAcceptAppointmentInput {
  repairOfferId?: string | null;
  appointmentId: string;
  appointmentISO: any;
}

export interface DriverInfoInput {
  driverName?: string | null;
  driverEmail?: string | null;
  driverPhone?: string | null;
  driverAddress?: string | null;
  driverCity?: string | null;
  driverZip?: string | null;
}

export interface DriverInformation {
  driverName?: string | null;
  driverEmail?: string | null;
  driverPhone?: string | null;
  driverAddress?: string | null;
  driverCity?: string | null;
  driverZip?: string | null;
  clientNumber?: string | null;
  isPrivacyAndTermsSubmitted?: boolean | null;
}

export interface DriverInformationInput {
  driverName?: string | null;
  driverEmail?: string | null;
  driverPhone?: string | null;
  driverAddress?: string | null;
  driverCity?: string | null;
  driverZip?: string | null;
  clientNumber?: string | null;
  isPrivacyAndTermsSubmitted?: boolean | null;
}

export interface DriverRequestCustomAppointmentInput {
  repairOfferId?: string | null;
}

export interface FleetDriverInfoInput {
  fleetDriverName?: string | null;
  licensePlate?: string | null;
}

export interface ImageUpload {
  file: any;
  type: DamageReportImageType;
}

export interface InitChecklistDamageReportInput {
  checkType: string;
  type?: DamageReportType | null;
}

export interface IsValidResetPasswordToken {
  token: string;
}

export interface PoliceReportInput {
  isRegistered?: boolean | null;
  policeOfficerName?: string | null;
  reportNumber?: string | null;
  reason?: string | null;
}

export interface ResetPasswordInput {
  password: string;
  passwordConfirm: string;
  token: string;
}

export interface SendResetPasswordLinkInput {
  email: string;
}

export interface ServiceLightsReportInput {
  carLocation?: string | null;
  driverName?: string | null;
  driverEmail?: string | null;
  driverPhone?: string | null;
  driverCity?: string | null;
  driverZip?: string | null;
  reporterName?: string | null;
  reporterEmail?: string | null;
  clientNumber?: string | null;
  driverAddress?: string | null;
  mileage: number;
  serviceLights: DamageCategoryType[];
  imagesIds?: string[] | null;
}

export interface TelematicReportInput {
  images: ImageUpload[];
  location: string;
  severity: string;
  speed: number;
  impactAngle: number;
}

export interface UpdateAccidentQuestionsInput {
  tripPurpose?: AccidentQuestionsTripTypeEnum | null;
  validDriverLicense?: boolean | null;
  leftAccidentScene?: boolean | null;
  alcoholBeveragesConsumes?: boolean | null;
  bloodSampleTaken?: boolean | null;
  wasAlcoholDrugTestPerformed?: boolean | null;
  alcoholConcentration?: number | null;
}

export interface UpdateCarsAvailabilityInput {
  availability: CarAvailabilityStatus;
  carVins: string[];
}

export interface UpdateChecklistReportInput {
  isTermsAndConditionAccepted?: boolean | null;
  isCompleted?: boolean | null;
  checks?: ChecklistReportCheckInput[] | null;
}

export interface UpdateCompletedDamageReportInformationInput {
  carPartsTypes?: CarPartType[] | null;
  isMinor?: boolean | null;
  categoryTypes?: DamageCategoryType[] | null;
  status?: DamageReportStatusAllowedForUpdate | null;
  fleetComment?: string | null;
  attachedDamageImages?: AttachedDamageImage[] | null;
}

export interface UpdateDamageImageInput {
  damageReportId: string;
  type?: DamageReportImageType | null;
  removeDamageReportImageId?: string | null;
  file?: any | null;
}

export interface UpdateDamageReport {
  fleetComment?: string | null;
  carPartsTypes?: CarPartType[] | null;
  categoryTypes?: DamageCategoryType[] | null;
}

export interface UpdateDamageReportInformationInput {
  carPartsTypes?: CarPartType[] | null;
  damageDescription?: string | null;
  isMinor?: boolean | null;
  categoryTypes?: DamageCategoryType[] | null;
}

export interface UpdateDamageReportInput {
  damageReportId: string;
  damageReport: UpdateDamageReport;
}

export interface UpdateDriverCameraAccessConsentInput {
  isCameraAccessConsent: boolean;
}

export interface UpdateRegistrationData {
  vin?: string | null;
  firstRegistration?: any | null;
  licensePlate?: string | null;
  carRegistrationCompany?: string | null;
  inspectionDate?: any | null;
  driverName?: string | null;
  driverEmail?: string | null;
  driverAddress?: string | null;
  driverZip?: string | null;
  driverCity?: string | null;
  driverPhone?: string | null;
}

export interface UserSignInInput {
  email: string;
  password: string;
}

export interface AccidentOverviewPageCompletedInput {
  driverInformConsent?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
