import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';

import { CarPartType, DamageReportImageType } from 'src/api/globalTypes';
import {
  DEFAULT_COMPRESSION_RATIO,
  DOCUMENT_IMAGE_COMPRESSION_RATIO,
  DOCUMENT_IMAGE_TYPES,
  ImageTypes,
  MAX_DOCUMENT_IMAGE_WIDTH,
  MAX_IMAGE_WIDTH,
} from 'src/apps/NewDriverApp/constants';
import {
  CarPartsDamageQuantity,
  CarPartsQuantityWithStatuses,
} from 'src/apps/NewDriverApp/pages/CarPartsDeprecated/components/Parts/types';
import { getLogger } from 'src/services/app-insights';
import { toBase64 } from 'src/services/image/encoder';

import { ChecklistReportedDamages } from '../flows/fleet/checklist/redux/types';
import { GetDamageReportHistory_damageReports } from '../graphql/queries/__generated__/GetDamageReportHistory';
import { ImageState } from '../redux/types/Types';
import { DamagePhotosCameraContext } from '../types';

const COMMON_HELPERS_LOGGER_CONTEXT_IDENTIFIER = 'utils/helpers';

const appInsights = getLogger(COMMON_HELPERS_LOGGER_CONTEXT_IDENTIFIER);

const REQUEST_CANCEL_TIMEOUT = 20000;

export interface DamageCarPartsData {
  carParts?: {
    type: CarPartType;
  }[];
}

export const damagedParts = (damageReports: DamageCarPartsData[]): string[] => {
  return damageReports.reduce<string[]>((parts, damage) => {
    const types = (damage.carParts || []).map(p => p.type);
    return Array.from(new Set([...parts, ...types]));
  }, []);
};

export const getCarPartsQuantityAndStatuses = (
  damageReports: GetDamageReportHistory_damageReports[],
) => {
  return damageReports.reduce(
    (acc: CarPartsDamageQuantity<CarPartsQuantityWithStatuses>, damage) => {
      const carParts = damage.carParts.map(({ type }) => type);
      carParts.forEach(carPart => {
        if (carPart in acc) {
          acc[carPart].count += 1;
          acc[carPart].statuses = acc[carPart].statuses.add(damage.status as string);
        } else {
          acc[carPart] = {
            count: 1,
            statuses: new Set<string>([damage.status as string]),
          };
        }
      });
      return acc;
    },
    {} as CarPartsDamageQuantity<CarPartsQuantityWithStatuses>,
  );
};

export const getCarPartsDamageQuantity = (
  damageReports: GetDamageReportHistory_damageReports[],
) => {
  return damageReports.reduce((acc: CarPartsDamageQuantity, damage) => {
    const carParts = damage.carParts.map(({ type }) => type);
    carParts.forEach(carPart => {
      if (carPart in acc) {
        acc[carPart] += 1;
      } else {
        acc[carPart] = 1;
      }
    });
    return acc;
  }, {} as CarPartsDamageQuantity);
};

export const getCompressionRatio = (imageType?: ImageTypes) => {
  const isDocumentImage = DOCUMENT_IMAGE_TYPES.includes(imageType as ImageTypes);

  if (isDocumentImage) {
    return DOCUMENT_IMAGE_COMPRESSION_RATIO;
  }

  return DEFAULT_COMPRESSION_RATIO;
};

export const getMaxWidth = (imageType?: ImageTypes) => {
  if (imageType === ImageTypes.ACCIDENT_REPORT) {
    return MAX_DOCUMENT_IMAGE_WIDTH;
  }

  return MAX_IMAGE_WIDTH;
};

export const findDamageReportImageByType =
  (imageType: DamageReportImageType) =>
  ({ context }: ImageState<DamagePhotosCameraContext>) => {
    const type = get(context, 'imageType');

    return imageType === type;
  };

export const downloadImage = async (url: string) => {
  const controller = new AbortController();
  setTimeout(() => controller.abort(), REQUEST_CANCEL_TIMEOUT);

  const data = await fetch(url, {
    signal: controller.signal,
  });

  return data.blob();
};

export const urlToBase64 = async (url: string | null | undefined) => {
  if (!url) {
    return null;
  }

  try {
    const file = await downloadImage(url);
    return toBase64(file);
  } catch (err) {
    appInsights.trackException({
      exception: err as Error,
      properties: { operation: 'ImageBase64Convert', url },
    });
    return null;
  }
};

export const getMasterCarPartName = (carPart: CarPartType) =>
  carPart.replace(/_\d+$/, '') as CarPartType;

export const groupCarParts = (carParts: CarPartType[]) => {
  return groupBy(carParts, getMasterCarPartName);
};

export const getDamageReportsWithUniqMasterTypes = <T extends ChecklistReportedDamages>(
  damageReports: T[],
): T[] =>
  damageReports.map(damageReport => {
    const carPartsWithMasterName = damageReport.carParts.map(carPart => ({
      ...carPart,
      type: getMasterCarPartName(carPart.type),
    }));

    const uniqMasterCarParts = uniqBy(carPartsWithMasterName, 'type');

    return {
      ...damageReport,
      carParts: uniqMasterCarParts,
    };
  });
