import { createAction } from '@reduxjs/toolkit';

import {
  AcceptLegalAgreement,
  AdditionalInformation,
  AdditionalServiceType,
  BodyStyle,
  CarPartType,
  DamageCategoryType,
  DriverInformationInput,
} from 'src/api/globalTypes';
import { WebAppFlows } from 'src/apps/NewDriverApp/constants';
import * as menu from 'src/apps/NewDriverApp/flows/fleet/menu/redux/actions';
import * as reporterType from 'src/apps/NewDriverApp/flows/fleet/reporter-type/redux/actions';
import { GetDamageReportHistory_damageReports } from 'src/apps/NewDriverApp/graphql/queries/__generated__/GetDamageReportHistory';
import { ThemeOverrides } from 'src/apps/NewDriverApp/theme/ThemeInterface';

import {
  AssetsReducer,
  CompletingStatus,
  DateAndLocationReducer,
  DriverCameraConsentData,
  ImageState,
  InsuranceInformationReducer,
  NotificationTypeReducer,
  OCRReducerInterface,
  OtherAccidentPartyReducer,
  RouteChanged,
  ScheduleAppointmentInputType,
  CarInfo,
} from './types/Types';
import { RouteData } from '../containers/Wizard/types';
import { UpdateChecklistImageActionPayload } from '../flows/fleet/checklist/redux/types';
import { AppConfiguration_appConfiguration } from '../graphql/queries/__generated__/AppConfiguration';
import { GetDamageReportInfoNew_damageReportInfo } from '../graphql/queries/__generated__/GetDamageReportInfoNew';

export type AppConfiguration = {
  configuration: AppConfiguration_appConfiguration;
  initParams?: Record<string, string>;
};

export const initApp = createAction('INIT_APP');

export const initAppStart = createAction('INIT_APP_START');
export const initAppSuccess = createAction('INIT_APP_SUCCESS');
export const initAppFailure = createAction<{ error: Error }>('INIT_APP_FAILURE');
export const displaySpinner = createAction('DISPLAY_SPINNER');
export const hideSpinner = createAction('HIDE_SPINNER');

export const setAppConfiguration = createAction<AppConfiguration>('SET_APP_CONFIGURATION');
export const setTheme = createAction<ThemeOverrides>('SET_THEME');
// set init params only
export const setInitParams = createAction<Record<string, string>>('SET_INIT_PARAMS');

export const goCustomerSite = createAction('GO_CUSTOMER_SITE');

export { menu, reporterType };
export const onBoardingCompleted = createAction('ONBOARDING_COMPLETED');
export const carBodyTypesCompleted = createAction<{ carBodyType: BodyStyle | null }>(
  'CAR_BODY_TYPES_COMPLETED',
);

export const photoOnboardingCompleted = createAction('PHOTO_ONBOARDING_COMPLETED');

export const damageTypesCompleted = createAction('DAMAGE_TYPE_COMPLETED');

export const carPartsCompleted = createAction('CAR_PARTS_COMPLETED');
export const damagePhotosCompleted = createAction('DAMAGE_PHOTOS_COMPLETED');
export const cockpitImageStepCompleted = createAction('COCKPIT_IMAGE_STEP_COMPLETED');
export const damageReasonsCompleted = createAction('DAMAGE_REASONS_COMPLETED');
export const additionalServicesCompleted = createAction('ADDITIONAL_SERVICES_COMPLETED');
export const registrationDocumentCompleted = createAction('REGISTRATION_DOCUMENT_COMPLETED');
export const scheduleAppointmentCompleted = createAction('SCHEDULE_APPOINTMENT_COMPLETED');
export const overviewCompleted = createAction('OVERVIEW_COMPLETED');

// DAMAGE TYPE
export const selectDamageTypes = createAction<DamageCategoryType | DamageCategoryType[]>(
  'SELECT_DAMAGE_TYPES',
);

// CAR PARTS
export const selectCarParts = createAction<CarPartType[]>('SELECT_CAR_PARTS');
export const unselectCarParts = createAction<CarPartType[]>('UNSELECT_CAR_PARTS');
export const selectTempCarParts = createAction<CarPartType[]>('SELECT_TEMP_CAR_PARTS');

// DAMAGE REPORT HISTORY
export const getDamageReportHistory = createAction<{ isWithChecklistContext: boolean } | undefined>(
  'GET_DAMAGE_REPORT_HISTORY',
);
export const fetchDamageReportHistory = createAction('FETCH_DAMAGE_REPORT_HISTORY');
export const fetchDamageReportHistorySuccess = createAction<{
  damageReports: GetDamageReportHistory_damageReports[];
}>('FETCH_DAMAGE_REPORT_HISTORY_SUCCESS');

export const addDamageReportToHistory = createAction<GetDamageReportHistory_damageReports>(
  'ADD_DAMAGE_REPORT_TO_HISTORY',
);

export const updateDamageReportInHistory = createAction<GetDamageReportHistory_damageReports>(
  'UPDATE_DAMAGE_REPORT_IN_HISTORY',
);

export const deleteDamageReportFromHistory = createAction<{ damageReportId: string }>(
  'DELETE_DAMAGE_REPORT_FROM_HISTORY',
);

export const fetchDamageReportHistoryFailure = createAction<{ error: Error }>(
  'FETCH_DAMAGE_REPORT_HISTORY_FAILURE',
);

export const updateHistoryDamageReportImage = createAction<{
  damageReportId: string;
  data: UpdateChecklistImageActionPayload;
}>('UPDATE_HISTORY_DAMAGE_REPORT_IMAGE');

// IMAGE_UPLOAD
export const imageUploadStart = createAction<{ internalId: string }, 'IMAGE_UPLOAD'>(
  'IMAGE_UPLOAD',
);
export const imageUploadProgressUpdate = createAction<
  { internalId: string; uploadProgress: number; context?: unknown },
  'IMAGE_UPLOAD_PROGRESS_UPDATE'
>('IMAGE_UPLOAD_PROGRESS_UPDATE');
export const imageUploadSuccess = createAction<
  {
    internalId: string;
    id: string;
    fullUrl: string;
    context?: unknown;
    createdAt?: Date;
    isDeletable?: boolean;
  },
  'IMAGE_UPLOAD_SUCCESS'
>('IMAGE_UPLOAD_SUCCESS');
export const imageUploadFailure = createAction<
  { internalId: string; error: Error; context?: unknown },
  'IMAGE_UPLOAD_FAILURE'
>('IMAGE_UPLOAD_FAILURE');

// CAR IDENTIFICATION
export const resetCarIdentification = createAction('RESET_CAR_IDENTIFICATION');

export const carIdentificationSetCarInfo = createAction<
  { carInfo: CarInfo },
  'CAR_IDENTIFICATION_IMAGE_UPLOAD_SUCCESS'
>('CAR_IDENTIFICATION_IMAGE_UPLOAD_SUCCESS');

// IMAGES
export const initImages = createAction<ImageState[], 'INIT_IMAGES'>('INIT_IMAGES');
export const removeImage = createAction<{ internalId: string }, 'REMOVE_IMAGE'>('REMOVE_IMAGE');
export const addImage = createAction<ImageState>('ADD_IMAGE');
export const imageVisibilityUpdate = createAction<{
  internalId: string;
  isVisibilityRestricted: boolean;
}>('IMAGE_VISIBILITY_UPDATE');

// GET DAMAGE REPORT
export const getDamageReportInfo = createAction('GET_DAMAGE_REPORT_INFO');
export const getDamageReportInfoSuccess = createAction<{
  info: GetDamageReportInfoNew_damageReportInfo;
}>('GET_DAMAGE_REPORT_INFO_SUCCESS');
export const getDamageReportInfoFailure = createAction<{ error: Error }>(
  'GET_DAMAGE_REPORT_INFO_FAILURE',
);
export const setDamageReportInfoLoaded = createAction<boolean>('SET_DAMAGE_REPORT_INFO_LOADED');
export const setDamageReportInfoStatus = createAction<string>('SET_DAMAGE_REPORT_INFO_STATUS');
export const setDamageReportInfoMinority = createAction<boolean | null | undefined>(
  'SET_DAMAGE_REPORT_INFO_MINORITY',
);
export const setDamageReportInfoFleetComment = createAction<string>(
  'SET_DAMAGE_REPORT_INFO_FLEET_COMMENT',
);

// COMPLETE DAMAGE REPORT
export const completeDamageReport = createAction('COMPLETE_DAMAGE_REPORT');
export const completeDamageReportSuccess = createAction('COMPLETE_DAMAGE_REPORT_SUCCESS');
export const completeDamageReportFailure = createAction<{ error: Error }>(
  'COMPLETE_DAMAGE_REPORT_FAILURE',
);
export const setDamageReportIsCompleting = createAction<boolean>('SET_DAMAGE_REPORT_IS_COMPLETING');

// DAMAGE REASONS
export const setDamageReasonDescription = createAction<string>('SET_DAMAGE_REASON_DESCRIPTION');
export const setDamageReason = createAction<string>('SET_DAMAGE_REASON');

// ADDITIONAL SERVICES
export const toggleAdditionalService = createAction<{
  id: string;
  flag: boolean;
  type: AdditionalServiceType;
}>('TOGGLE_ADDITIONAL_SERVICE');

// OSR DATA
export const getOCRRegistrationData = createAction('GET_OCR_REGISTRATION_DATA');
export const getOCRRegistrationDataSuccess = createAction<Partial<OCRReducerInterface>>(
  'GET_OCR_REGISTRATION_DATA_SUCCESS',
);
export const getOCRRegistrationDataFailure = createAction<{ error: Error }>(
  'GET_OCR_REGISTRATION_DATA_FAILURE',
);

export const setRegistrationImage = createAction<{ image: ImageState }>(
  'SET_REGISTRATION_REPORT_IMAGE',
);

export const updateRegistrationData = createAction<Partial<OCRReducerInterface>>(
  'UPDATE_REGISTRATION_DATA',
);

export const finishOcrStep = createAction('FINISH_OCR_STEP');

export const updateAdditionalInformationData = createAction<{
  data: AdditionalInformation;
}>('UPDATE_ADDITIONAL_INFORMATION_DATA');

export const completeAdditionalInformation = createAction('COMPLETE_ADDITIONAL_INFORMATION');

// DRIVER INFORMATION
export const setDriverInformationData = createAction<Partial<DriverInformationInput>>(
  'SET_DRIVER_INFORMATION_DATA',
);
export const updateDriverInformationData = createAction<{
  data: DriverInformationInput;
}>('UPDATE_DRIVER_INFORMATION_DATA');

export const completeDriverInformationData = createAction<{
  data: DriverInformationInput;
}>('COMPLETE_DRIVER_INFORMATION_DATA');

// SCHEDULE APPOINTMENT
export const setScheduleAppointmentDate = createAction<ScheduleAppointmentInputType>(
  'SET_SCHEDULE_APPOINTMENT_DATE',
);

// report new damage
export const reportNewDamage = createAction('REPORT_NEW_DAMAGE');

export const saveLegalAgreement = createAction<Record<string, boolean>>('SAVE_LEGAL_AGREEMENT');
export const setInitialAgreements = createAction<AcceptLegalAgreement[]>('SET_INITIAL_AGREEMENTS');
export const setDriverCameraConsent = createAction<{ id: string; data: DriverCameraConsentData }>(
  'SET_DRIVER_CAMERA_CONSENT',
);

export const carIdentificationCompleted = createAction('CAR_IDENTIFICATION_COMPLETED');
export const carIdentification = createAction('CAR_IDENTIFICATION');
export const carIdentificationSuccess = createAction('CAR_IDENTIFICATION_SUCCESS');
export const carIdentificationFailure = createAction<{ error: Error }>(
  'CAR_IDENTIFICATION_FAILURE',
);

export const getCustomerTheme = createAction('GET_CUSTOMER_THEME');
export const getCustomerThemeSuccess = createAction<{ getTheme: ThemeOverrides }>(
  'GET_CUSTOMER_THEME_SUCCESS',
);
export const getCustomerThemeFailure = createAction<{ error: Error }>('GET_CUSTOMER_THEME_FAILURE');

export const routeChanged = createAction<RouteChanged>('ROUTE_CHANGED');

// refresh state
export const refreshStateForNewReport = createAction('REFRESH_STATE_FOR_NEW_REPORT');

export const resetState = createAction('RESET_STATE');

export const resetReport = createAction('RESET_REPORT');

export const getOCRData = createAction('GET_OCR_DATA');

export const setAssetsData = createAction<AssetsReducer>('SET_ASSETS_DATA');

// auth state
export const setChecklistCode = createAction<string | null>('SET_CHECKLIST_CODE');

export const setCode = createAction<string | null>('SET_CODE');

export const setDamageCode = createAction<string | null>('SET_DAMAGE_CODE');

export const setCarCode = createAction<string | null>('SET_CAR_CODE');

export const setFlowKey = createAction<WebAppFlows | null>('SET_FLOW_KEY');

export const setExtendedPermissions = createAction<string[] | null>('SET_EXTENDED_PERMISSIONS');

export const setHasAuth = createAction<string | null>('SET_HAS_AUTH');

export const setManagerCode = createAction<string | null>('SET_MANAGER_CODE');

export const setIsLoggingOut = createAction<boolean>('SET_IS_LOGGING_OUT');

export const setNotificationData = createAction<NotificationTypeReducer>('SET_NOTIFICATION_DATA');

export const resetNotification = createAction('RESET_NOTIFICATION');

export const processNavigationFlowStep = createAction('PROCESS_NAVIGATION_FLOW_STEP');

export const processNavigationFlowStepSuccess = createAction(
  'PROCESS_NAVIGATION_FLOW_STEP_SUCCESS',
);

// Insurance information
export const updateInsuranceInformationData = createAction<InsuranceInformationReducer>(
  'UPDATE_INSURANCE_INFORMATION_DATA',
);

// Date and location information
export const updateDateAndLocationData = createAction<DateAndLocationReducer>(
  'UPDATE_DATE_AND_LOCATION_DATA',
);

// Date and location information
export const updateOtherAccidentPartyData = createAction<OtherAccidentPartyReducer>(
  'UPDATE_OTHER_ACCIDENT_PARTY_DATA',
);

export const processNavigationFlowStepFailure = createAction<Error>(
  'PROCESS_NAVIGATION_FLOW_STEP_FAILURE',
);

// Damage report compleating status

export const updateDamageReportCompletingStatus = createAction<CompletingStatus>(
  'UPDATE_DAMAGE_REPORT_COMPLETING_STATUS',
);
export interface CallbackState {
  id: string;
  status: 'completed' | 'error' | 'timeout';
  message?: string;
  url?: string | undefined;
}

export interface CallbackFlowResult {
  flow?: WebAppFlows;
  status: 'completed' | 'error' | 'timeout';
  message?: string;
}

// CallbackUrl behavior
export const setCallbackState = createAction<CallbackState>('SET_CALLBACK_STATE');
export const setCallbackFlowResult = createAction<CallbackFlowResult>('SET_CALLBACK_FLOW_RESULT');

// wizard
export const resetWizardData = createAction<string>('RESET_WIZARD_DATA');
export const setWizardData = createAction<{ name: string; data?: RouteData }>('SET_WIZARD_DATA');
